import { useState } from "react"

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Modal(props) {

    const [pending, setPending] = useState(false)

    const [deleted, setDeleted] = useState(false)

    const {showModal, setShowModal, setHints, setProgress, host, url, setUrl, token, method, setMethod, setErrorCode, fetchComments, fetchResults, user, setComment} = props

    function handleClick(event, id) {
        setPending(true)
        console.log("fired!!")
        fetch(url, {
            method: method,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
        //strange bug here, therefore ignore 404 errors
            if (!response.ok && response.status !== 404) {
                setErrorCode(response.status)
                return response.text().then(text => {
                throw new Error(text)
                });
            } else {
            return response.json()
            }
        })
        .then(data => {
            document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; samesite=lax";
            console.log("gelöscht!!!!")
            console.log(data) // JSON data parsed by `data.json()` call
            setDeleted(true)
            setPending(false)
            fetchResults && fetchResults()
            fetchComments && fetchComments()
        })     
        .catch(error => {
            setHints(<p className="card-text">Beim Löschen deines Kommentars ist ein Fehler aufgetreten.</p>)
            console.log(error)
            setProgress(-1)
            })
    }

    const modalTheme = createTheme({
        palette: {
            primary: {
              light: '#6746c3',
              main: '#5E40C9',
              dark: '#492fa7'
            },
            secondary: {
              light: '#e35183',
              main: '#AD1457',
              dark: '#78002e'
            }
        },
        typography: {
            fontFamily: 'var(--bs-font-sans-serif)',
            button: {
                textTransform: 'none'
            }
        }
    })

    function hideModal() {
        setShowModal(false)
        if (url.includes("delete") && deleted) {
            window.location.reload()
        }
        setTimeout( () => {
            deleted === true && setDeleted(false)
            setComment && setComment(prevState => {
                return {
                    ...prevState,
                    content: ""
                }
            })
            if ( url.includes("delete") ) {
                setMethod("PUT")
                setUrl(host + "user")
            } else {
                setMethod("POST")
                setUrl(host + "comment")
            }
        }, 300)
    }

    return (
        <ThemeProvider theme={modalTheme}>
            <Dialog
                open={showModal}
                onClose={hideModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{! deleted ? "Löschen?" : "Gelöscht!"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { ! deleted ? `Bist du sicher, dass du ${url.includes("comment") ? "diesen Kommentar" : url.includes("user") ? "diesen Eintrag" : "die gesamte Umfrage"} löschen möchtest, ${user.name}?`
                            : `Alles klar, ${user.name}! Ich habe ${url.includes("comment") ? "den Kommentar" : url.includes("user") ? "den Eintrag" : "die gesamte Umfrage"} gelöscht.
                            ${url.includes("delete") ? "Du kannst jetzt wieder von vorne loslegen." : ""}`
                    }
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                {! deleted && 
                    <Button name="close" onClick={handleClick} color="primary" autoFocus disabled={pending}>
                        Ja
                    </Button>
                }
                <Button name="delete" onClick={hideModal} color="primary">
                    {! deleted ? "Nein" : "Schließen"}
                </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

export default Modal