function Alert(props) {
    const {errorCode, hints, svgEmoji} = props
    return (
        <div className="alert alert-danger m-0" role="alert">
            <h2>Fehler {errorCode} {svgEmoji("🤨")}</h2>
            {hints}
        </div>
    )
}

export default Alert