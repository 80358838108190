function TitleAndDescription(props) {
    const {title, description, location, duration, svgEmoji, finalDate} = props
    return (
        <div className="card shadow-sm my-3 m-lg-3" style={{maxWidth: 600, display: "inline-block"}}>
            <div className="card-body">
                <h2 className="card-title text-primary">{title}</h2>
            </div>
            <ul className="list-group list-group-flush text-start">
                <li className="list-group-item">
                    {svgEmoji("📅")} Termin: {
                        finalDate ? <span className="text-success fw-bold">{new Date(finalDate).toLocaleString([], {dateStyle: "medium", timeStyle: "short"})}</span>
                        : <span className="text-danger fw-bold">wird noch gesucht</span>
                    }
                </li>
                { location.startsWith("https://") ?
                    <li className="list-group-item">{svgEmoji("🎥")} Webkonferenz: <a href={location}>Link zum Meeting</a></li>
                    : <li className="list-group-item">{svgEmoji("📍")} Ort: {location}</li>
                }
                <li className="list-group-item">{svgEmoji("🕒")} Dauer: {duration.hours === 1 ? "1 Stunde" : duration.hours > 1 ? `${duration.hours} Stunden` : ""}{(duration.hours && duration.minutes) ? " und" : ""}{duration.minutes === 1 ? " 1 Minute" : duration.minutes > 1 ? ` ${duration.minutes} Minuten` : ""}</li>            </ul>
            { description !== "" && 
                <div className="card-body">
                    <h3 className="text-primary h4">Beschreibung</h3>
                    <p className="card-text text-start">{description}</p>
                </div>
            }
        </div>
    )
}

export default TitleAndDescription