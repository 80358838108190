import { CheckLg, XLg, QuestionLg } from "react-bootstrap-icons"


function TableCell(props) {
    const {date, userDates} = props
    if (userDates.yes.includes(date)) {
        return <td className="table-success text-success"><span aria-hidden="true"><CheckLg /></span><span className="visually-hidden">Ja</span></td>
    } else if (userDates.maybe.includes(date)) {
        return <td className="table-warning text-warning"><span aria-hidden="true">( <CheckLg /> )</span><span className="visually-hidden">Vielleicht</span></td>
    } else if (userDates.no.includes(date)) {
        return <td className="table-danger text-danger"><span aria-hidden="true"><XLg /></span><span className="visually-hidden">Nein</span></td>
    } else {
       return <td className="table-secondary text-secondary"><span aria-hidden="true"><QuestionLg /></span><span className="visually-hidden">Keine Angabe</span></td>
    }   
}

export default TableCell