import SingleDateListItem from "./SingleDateListItem"

function DateSummary(props) {
    const {dates} = props.user
    function getDates(arr) {
        return arr.length > 0 && 
        <div className="mb-3">
            <p className="card-text">An diesen Terminen hast du {arr === dates.no ? "keine" : arr === dates.maybe ? "vielleicht" : ""} Zeit:</p>
            <ul className="list-group list-group-flush">
                {arr.map(date => <SingleDateListItem date={date} key={date} />)}
            </ul>
        </div>
      }
    return (
        <div className="date-summary">
            {getDates(dates.yes)}
            {getDates(dates.maybe)}
            {getDates(dates.no)}
        </div>
    )
}

export default DateSummary