function SingleDateListItem(props) {
    return (
        <li className="list-group-item">
            <time dateTime={props.date}>
                {new Date(props.date).toLocaleString([], {dateStyle: 'full', timeStyle: 'short'})}
            </time>
        </li>
    )
}

export default SingleDateListItem