import {useState, useEffect} from "react"
import { nanoid } from "nanoid";
import emoji from 'react-easy-emoji'
import { useWindowSize } from "react-use"
import Confetti from "react-confetti"

import Form from "./Form"
import Results from "./Results"
import Alert from "./Alert"
import Spinner from "./Spinner"
import Modal from "./Modal"


function Main() {

  const { width, height } = useWindowSize()

  const [progress, setProgress] = useState(0)

  const [hints, setHints] = useState(<p className="card-text mx-3 mt-3 mb-2">Moment, ich muss kurz überlegen …</p>)

  const host = process.env.REACT_APP_HOST

  const [url, setUrl] = useState(host + "user")

  const [method, setMethod] = useState("POST")

  const [token, setToken] = useState("")

  const [cookieConsent, setCookieConsent] = useState(false)


  function svgEmoji (input) {
    return emoji(input, {
      baseUrl: host + 'static/media/emojis',
      ext: '.svg',
      size: ''
    })
  }

  function getRandomEmoji() {
    const emojiArr = ["🧍", "🧍‍♂️", "🧍‍♀️", "💃", "🕺"]
    const randomNumber = Math.floor(Math.random() * 5)
    const randomEmoji = emojiArr[randomNumber]
    return randomEmoji
}

  const [user, setUser] = useState({
    name: "",
    emoji: getRandomEmoji(),
    email: "",
    dates: {
      yes: [],
      maybe: [],
      no: []
    }
  })

  const [settings, setSettings] = useState({
    adminId: "",
    title: "",
    location: "",
    duration: {
      hours: 0,
      minutes: 0
    },
    description: "",
    dates: [{_id: nanoid(), date: new Date().toISOString()}],
    finalDate: "",
    everybodyCanEdit: false
  })

  const [setupDone, setSetupDone] = useState(false)

  const [results, setResults] = useState([])

  const [errorCode, setErrorCode] = useState(0)

  const [showModal, setShowModal] = useState(false)

  function askDeletePoll() {
    setUrl(host + "delete")
    setMethod("DELETE")
    setShowModal(true)
  }

  function addDate(event) {
    setSettings(prevState => {
      return {
        ...prevState,
        dates: prevState.dates.concat({_id: nanoid(), date: new Date().toISOString()})
      }
    })
  }

  function getEndTime(settings) {
    let endTime = new Date(settings.finalDate)
    endTime.setHours(endTime.getHours() + settings.duration.hours)
    endTime.setMinutes(endTime.getMinutes() + settings.duration.minutes)
    return endTime
}

const [event, setEvent] = useState({
    title: "",
    description: "",
    startTime: "",
    endTime: "",
    location: ""
  })

  useEffect(() => {
    fetch(host + "settings")
    .then(response => {
      if (!response.ok && response.status !== 404) {
        setErrorCode(response.status)
        return response.text().then(text => {
            throw new Error(text)
        })
      }
      return response.json();
    })
    .then(data => {
      if (!data.success) {
        console.log("No settings found, please create them first")
        setHints(<p className="card-text">Hallo! Mein Name ist Poodle. Ich begleite dich – den zukünftigen Admin – bei der Einrichtung dieser Terminumfrage. Bitte verrate mir zunächst deinen Namen.</p>)
        setProgress(1)
      } else {
        setSettings(data.data)
        setEvent({
          title: data.data.title,
          description: data.data.description + (data.data.location.startsWith("https://") ? ` – Link zum Meeting: ${data.data.location}` : ""),
          startTime: "",
          endTime: "",
          location: data.data.location.startsWith("https://") ? "" : data.data.location,
        })
        setSetupDone(true)
        console.log("Settings found, do we have a final date?")
        if (data.data.finalDate) {
          setEvent(prevState => {
            return {
              ...prevState,
              startTime: data.data.finalDate,
              endTime: getEndTime(data.data)
            }
          })
        }
        console.log("Was the user already here?")
        const urlParams = new URLSearchParams(window.location.search)
        const cookieToken = document.cookie.split('; ').find(row => row.startsWith('token=')) ? document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1] : ""
        if (urlParams.has("token") ) {
          setToken(urlParams.get("token"))
        } else if (cookieToken) {
          setToken(cookieToken)
        } else {
          if (data.data.finalDate) {
            const id = nanoid(24)
            sendData(host + "get-token", method, {id: id})
            .then(data => {
              setUser(prevState => {
                return {
                  ...prevState,
                  _id: id,
                  name: "Anonymous",
                  emoji: getRandomEmoji()
                }
              })
              setToken(data.token)
            })
            .catch(error => {
              console.log(error)
              setHints(<p className="card-text">Leider ist ein Fehler aufgetreten. Bitte schau für mehr Details in der Konsole und im Server-Log nach.</p>)
              setProgress(-1)
            })
            setHints(<p className="card-text">Houston, wir haben einen Termin!! {svgEmoji("🥳🎉🥂")}</p>)
            setProgress(3)
          }
          else {
            console.log("Nope. Hello new user, please vote!")
            setHints(<p className="card-text">Hallo! Mein Name ist Poodle. Wie soll ich dich nennen?</p>)
            setProgress(1)
          }
        }
      }
    })
    .catch(error => {
      console.log(error)
      setHints(<p className="card-text">Beim Abrufen der Einstellungen ist ein Fehler aufgetreten. Bitte schau für mehr Details in der Konsole und im Server-Log nach.</p>)
      setProgress(-1)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

useEffect(() => {
  if (token && ! progress) {
    fetch(host + "login", {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (!response.ok) {
        setErrorCode(response.status)
        return response.text().then(text => {
            throw new Error(text)
        })
      }
      return response.json();
    })
    .then(data => {
      setUser(data.data)
      console.log("Welcome back!")
      if (settings.finalDate) {
        setHints(<p className="card-text">{data.data.name}, wir haben einen Termin!! {svgEmoji("🥳🎉🥂")}</p>)
        setProgress(3)
      } else {
        setHints(<p className="card-text" id="hints">Willkommen zurück, {data.data.name}! {svgEmoji("🙃")} Was möchtest du tun?</p>)
        setProgress(3)
      }
    })
    .catch(error => {
      console.log(error)
      console.log("Something went wrong with the token. I will treat you as new user instead.")
      setHints(<p className="card-text">Hallo! Mein Name ist Poodle. Wie soll ich dich nennen?</p>)
      setProgress(1)
    })
  }
}, [token])

useEffect(() => {
  window.scrollTo(0, 0)
}, [progress])

    // console.log("datesObj:", datesObj)
    // console.log("user:", user)
    // console.log("dates:", dates)


    function moveOn(event) {
      event.preventDefault()

      if (progress === 1) {
        const id = nanoid(24)

        sendData(host + "get-token", method, {id: id})
        .then(data => {
          setUser(prevState => {
            return {
              ...prevState,
              _id: id
            }
          })
          setToken(data.token)
        })
        .catch(error => {
          console.log(error)
          setHints(<p className="card-text">Leider ist ein Fehler aufgetreten. Bitte schau für mehr Details in der Konsole und im Server-Log nach.</p>)
          setProgress(-1)
        })
        console.log(settings)
        if (! settings.adminId) {
          setSettings(prevState => {
            return {
              ...prevState,
              adminId: id
            }
          })
          setHints(<p className="card-text">Schön, dich kennenzulernen, {user.name}! {svgEmoji("😀")} Such dir bitte zuallererst ein paar Zeiten aus, die für deinen Termin infrage kommen und lass uns ein paar Einstellungen festlegen.</p>)
          setProgress(2)
        } else {
          checkDates()
          setHints(
              <p className="card-text mb-0">
                Hey {user.name}! {svgEmoji("🙂")} Schön, dass du dabei bist. Wir suchen einen Termin – und zwar hierfür:
              </p>
          )
          setProgress(3)
        }

      } else if (progress === 2) {
        sendData(host + "settings", method, settings, token)
          .then(data => {
            console.log(data) // JSON data parsed by `data.json()` call
            checkDates()
            if (setupDone && settings.finalDate) {
              setEvent(prevState => {
                return {
                  ...prevState,
                  startTime: settings.finalDate,
                  endTime: getEndTime(settings)
                }
              })
              setHints(<p className="card-text">Gute Arbeit {user.name}, wir haben einen Termin!! {svgEmoji("🥳🎉🥂")}</p>)
              setProgress(3)
            } else {
              setHints(<p className="card-text">Perfekt, ich habe deine Einstellungen gespeichert, {user.name}! {svgEmoji("🙂")} An welchen Terminen hast du denn selbst Zeit?</p>)
              setProgress(4)
            }
          })     
          .catch(error => {
            console.log(error)
            setHints(<p className="card-text">Beim Setzen der Einstellungen ist ein Fehler aufgetreten. Bitte schau für mehr Details in der Konsole und im Server-Log nach.</p>)
            setProgress(-1)
          })

      } else if (progress === 3) {
        if (! user.dates.yes.length && ! user.dates.maybe.length && ! user.dates.no.length) {
          setHints(<p className="card-text mb-3">Super, {user.name}! Lass uns nun gemeinsam nach passenden Tagen und Uhrzeiten schauen. {svgEmoji("🧐")}{svgEmoji("📅")} Wann hast du Zeit?</p>)
          setProgress(4)
        } else {
          setUrl(host + "user/" + user._id)
          setMethod("PUT")
          if (settings.adminId === user._id) {
            setHints(<p className="card-text">Kein Problem, {user.name}! {svgEmoji("🙂")} Da du der Admin bist, kannst du hier die Einstellungen deiner Terminumfrage bearbeiten und jederzeit Termine hinzufügen oder entfernen.</p>)
            setProgress(2)
          } else {
            checkDates()
            setHints(<p className="card-text">In Ordnung, {user.name}! {svgEmoji("🙂")} Ändere deine Angaben nach Belieben und sag mir Bescheid, wenn es weitergehen kann.</p>)
            setProgress(4)
          }
        }

      } else if (progress === 4) {
        setHints(<p className="card-text">Hier siehst du deine Angaben noch einmal in der Übersicht:</p>)
        setProgress(5)

      } else if (progress === 5) {
        sendData(url, method, user, token)
        .then(data => {
          console.log(data) // JSON data parsed by `data.json()` call
          if (!data.success) {
            setErrorCode(403)
            throw new Error("could not send data")
          }
          if (method === "POST") {
            setToken(data.token)
            if (cookieConsent) {
              const sortedDates = [...settings.dates]
              sortedDates.sort(function(a,b){
                return new Date(b.date) - new Date(a.date);
              })
              const lastDate = new Date(sortedDates[0].date)
              const cookieExpire = new Date(lastDate.setUTCDate(lastDate.getUTCDate() + 1))
              document.cookie = `token=${data.token}; expires=${cookieExpire}; samesite=lax${process.env.REACT_APP_COOKIE_SECURITY ? "; " + process.env.REACT_APP_COOKIE_SECURITY : ""}`
            }
          }
          setResults([])
          setHints(<p className="card-text">Vielen Dank, deine Eintragungen wurden abgeschickt. {svgEmoji("🥳")} Hier habe ich die vorläufigen Ergebnisse für dich zusammengetragen. {svgEmoji("📊")}</p>)
          setProgress(6)
        })     
        .catch(error => {
          console.log(error)
          setHints(<p className="card-text">Beim Übermitteln deiner Daten ist ein Fehler aufgetreten. Bitte wende dich vertrauensvoll an den Poodle-Admin.</p>)
          setProgress(-1)
        })
      } 
    }

    function reset(event) {
        event.preventDefault()
        checkDates()
        setHints(<p className="card-text">Kein Problem, {user.name}! {svgEmoji("🙂")} Lass uns die Termine noch einmal in aller Ruhe durchgehen.</p>)
        setProgress(4)
    }

    function checkDates() {
        console.log(settings.dates, user.dates)
        const datesArr = settings.dates.map(date => date.date)
        setUser(prevState => {
          return {
            ...prevState,
            dates: {
              yes: prevState.dates.yes.filter(date => datesArr.includes(date) && date),
              maybe: prevState.dates.maybe.filter(date => datesArr.includes(date) && date),
              no: prevState.dates.no.filter(date => datesArr.includes(date) && date)
            }
          }
        })
      }

    // Example POST method implementation:
async function sendData(url = '', method = '', data = {}, token = '') {
    // Default options are marked with *
    const response = await fetch(url, {
      method: method, // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }
  

    return (
      <main>
        {
          (settings.finalDate && progress === 3) &&  <Confetti recycle={false} numberOfPieces={500} gravity={0.2} width={width} height={height} style={{position: "fixed"}} />
        }
        <div className={ (progress === 6)  ? "container" : "d-inline-block"}>
          <div className="bubble-container" >
            <div className="card mx-2 mx-lg-3 bg-light border-purple shadow overflow-hidden" id="date-card">
                { 
                  progress === -1 ? <Alert errorCode={errorCode} hints={hints} svgEmoji={svgEmoji} /> : 
                  progress === 0 ? <Spinner hints={hints} /> :
                  progress < 6 ? <Form moveOn={moveOn} reset={reset} progress={progress} setProgress={setProgress} setHints={setHints} user={user} setUser={setUser} settings={settings} setSettings={setSettings} setupDone={setupDone} hints={hints} addDate={addDate} askDeletePoll={askDeletePoll} svgEmoji={svgEmoji} event={event} method={method} cookieConsent={cookieConsent} setCookieConsent={setCookieConsent} /> :
                  <Results settings={settings} user={user} setUser={setUser} results={results} setResults={setResults} hints={hints} setHints={setHints} errorCode={errorCode} setErrorCode={setErrorCode} progress={progress} setProgress={setProgress} host={host} url={url} setUrl={setUrl} method={method} setMethod={setMethod} token={token} sendData={sendData} svgEmoji={svgEmoji} />
                }
                <Modal showModal={showModal} setShowModal={setShowModal} url={url} host={host} setUrl={setUrl} token={token} setHints={setHints} setProgress={setProgress} method={method} setMethod={setMethod} setErrorCode={setErrorCode} user={user} />
            </div>
          </div>
          <div className="text-end mb-5" style={{marginRight: "3.5rem", fontSize: "4rem"}}>{ svgEmoji("🐩") }</div>
        </div>
      </main>
    )
}

export default Main
