import { FadeIn } from "react-slide-fade-in"
import dayjs from "dayjs"
import ICalendarLink from "react-icalendar-link"
import { Trash } from "react-bootstrap-icons"


import DateChooser from "./DateChooser"
import SingleDateRadio from "./SingleDateRadio"
import DateSummary from "./DateSummary"
import TitleAndDescription from "./TitleAndDescription"


function Form(props) {

    const {progress, setProgress, setHints, user, setUser, settings, setSettings, setupDone, hints, addDate, askDeletePoll, svgEmoji, event, method, cookieConsent, setCookieConsent} = props

    function handleUserChange(event) {
        const { name, value, type } = event.target
        function updateArr(date, obj, value1, value2) {
            if (value1 === value2) {
                if  (! obj[value1].includes(date)) {
                    return [...obj[value1], name]
                }
                return [...obj[value1]]
            } else {
                if (! obj[value1].includes(date)) {
                    return [...obj[value1]]
                }
                const index = obj[value1].indexOf(date)
                return [...obj[value1].slice(0,index), ...obj[value1].slice(index+1)]
            }
        }
        type === "radio" ? 
        setUser(prevState => {
            return {
                ...prevState,
                dates: {
                    yes: updateArr(name, prevState.dates, "yes", value),
                    maybe: updateArr(name, prevState.dates, "maybe", value),
                    no: updateArr(name, prevState.dates, "no", value)
                }
            }
        }) :
        setUser(prevState => {
            return {
            ...prevState, 
            [name]: value
            }
        })
    }

    function handleClick(event, id) {
        const {name} = event.target
        if (name === "removeDates") {
            setSettings(prevState => {
                return {
                    ...prevState,
                    dates: prevState.dates.filter((item) => item._id !== id)
                }
            })
        } else {
            setHints(<p className="card-text">Hier sind die {! settings.finalDate ? "bisherigen " : ""}Ergebnisse{ user.name !== "Anonymous" ? `, ${user.name}` : ""}! {svgEmoji("📊")}</p>)
            setProgress(6)
        }
    }

    function handleDateChange(date, index) {
        setSettings(prevState => {
            return {
                ...prevState,
                dates: [
                    ...prevState.dates.slice(0, index),
                    { ...prevState.dates[index], date: dayjs(date).toISOString() },
                    ...prevState.dates.slice(index + 1)
                ]
            }
        })
        console.log(settings.dates)  
    }

    function handleSettingsChange(event) {
        const {name, value, valueAsNumber, type, checked} = event.target
        type === "text" ?
        setSettings(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        }) :
        type === "number" ? 
        setSettings(prevState => {
            return {
                ...prevState,
                duration: {
                    ...prevState.duration, [name]: valueAsNumber
                }
            }
        }) :
        name === "description" ?
        setSettings(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        }) :
        name === "finalDate" ?
        setSettings(prevState => {
            return {
                ...prevState,
                [name]: settings.finalDate === value ? "" : value
            }
        })
        :
        setSettings(prevState => {
            return {
                ...prevState,
                everybodyCanEdit: checked
            }
        })
    }

    function handleCookieConsent(event) {
        const {checked} = event.target
        setCookieConsent(checked)
    }

    return (
        <form id="form" className="p-3" onSubmit={props.moveOn} onReset={props.reset} style={progress < 4 ? {maxWidth: 800} : {}}>
            {   
                progress === 1 && 
                    <div className="mb-3">
                        {hints}
                        <div className="input-group mb-2">
                            <span className="input-group-text" id="name-label" aria-label="Name">{ svgEmoji(user.emoji) }</span>
                            <input id="name" name="name" className="form-control" type="text" placeholder="Dein Name" maxLength="25" aria-labelledby="name-label" onChange={handleUserChange} value={user.name} required />
                        </div>
                    </div>
            }{
                progress === 2 &&
                    <div className="mb-3">
                        {hints}
                        <FadeIn from="bottom" positionOffset={30} delayInMilliseconds={100} durationInMilliseconds={600}>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="title-label" aria-label="Title">{ svgEmoji("🔖") }</span>
                                <input id="title" name="title" className="form-control" type="text" placeholder="Titel deiner Veranstaltung" maxLength="40" aria-labelledby="title-label" onChange={handleSettingsChange} value={settings.title} required />
                            </div>
                        </FadeIn>
                        <FadeIn from="bottom" positionOffset={30} delayInMilliseconds={200} durationInMilliseconds={600}>
                            <div className="mb-3 text-start">
                                <label htmlFor="description" className="form-label">Beschreibung</label>
                                <textarea className="form-control" id="description" name="description" rows="5" placeholder="Beschreibung deiner Veranstaltung" onChange={handleSettingsChange} value={settings.description}></textarea>
                            </div>
                        </FadeIn>
                        <FadeIn from="bottom" positionOffset={30} delayInMilliseconds={300} durationInMilliseconds={600}>
                            <div className="input-group">
                                <span className="input-group-text" id="location-label" aria-label="Ort">{ settings.location.startsWith("https://") ? svgEmoji("🎥") : svgEmoji("📍") }</span>
                                <input id="location" name="location" className="form-control" type="text" placeholder="Ort deiner Veranstaltung" maxLength="200" aria-labelledby="location-label" aria-describedby="location-help" onChange={handleSettingsChange} value={settings.location} required />
                            </div>
                            <div id="location-help" className="form-text mb-3">Statt eines "echten" Ortes kannst du hier auch einen Link zu einer Webkonferenz einfügen (BBB, Jitsi, Zoom o. Ä.), wenn du dein Treffen online abhalten möchtest. {svgEmoji("💻")}</div>
                        </FadeIn>
                        <FadeIn from="bottom" positionOffset={30} delayInMilliseconds={400} durationInMilliseconds={600}>
                            <p className="text-start mb-2">Wie lange dauert der Termin?</p>
                            <div className="input-group mb-3 text-start flex-nowrap">
                                <span className="input-group-text" id="duration-hours-label" aria-label="Stunden">{ svgEmoji("🕒") }</span>
                                <input id="duration-hours" name="hours" className="form-control" type="number" placeholder="Stunden" min={0} max={24} aria-labelledby="duration-hours-label" onChange={handleSettingsChange} value={settings.duration.hours} required={! settings.duration.hours && ! settings.duration.minutes} />
                                <span className="input-group-text" id="duration-minutes-label" aria-label="Minuten">:</span>
                                <input id="duration" name="minutes" className="form-control" type="number" placeholder="Minuten" min={0} max={59} aria-labelledby="duration-minutes-label" onChange={handleSettingsChange} value={settings.duration.minutes} />
                                <span className="input-group-text" aria-label="Minuten">Stunden</span>
                            </div>
                        </FadeIn>
                        <FadeIn from="bottom" positionOffset={30} delayInMilliseconds={500} durationInMilliseconds={600}>
                            <div className="form-check form-switch text-start mb-3">
                                <input className="form-check-input" type="checkbox" name="everybodyCanEdit" id="everybody-can-edit" onChange={handleSettingsChange} checked={settings.everybodyCanEdit} />
                                <label className="form-check-label" htmlFor="everybody-can-edit">Jeder Nutzer soll jeden bearbeiten können</label>
                            </div>
                        </FadeIn>
                        <FadeIn from="bottom" positionOffset={30} delayInMilliseconds={600} durationInMilliseconds={600}>
                            <p className="text-start mb-2">Welche Termine möchtest du vorschlagen?</p>
                            {settings.dates.map((date, index) => 
                                <FadeIn from="bottom" positionOffset={30} durationInMilliseconds={600}>
                                    <DateChooser index={index} settings={settings} handleSettingsChange={handleSettingsChange} handleDateChange={handleDateChange} handleClick={handleClick} date={date} id={date._id} key={date._id} setupDone={setupDone} svgEmoji={svgEmoji} />
                                </FadeIn>
                            )}
                        </FadeIn>
                    </div>
            }{
                progress === 3 &&
                    <div className="mb-3">
                        {hints}
                        <FadeIn from="bottom" positionOffset={30} durationInMilliseconds={600}>
                            <TitleAndDescription title={settings.title} description={settings.description} location={settings.location} duration={settings.duration} finalDate={settings.finalDate} svgEmoji={svgEmoji} />
                        </FadeIn>
                        {settings.finalDate ?
                            <p className="card-text">Wenn du magst, speichere ihn in dir gleich in deinen Kalender ein. Oder schau dir nochmal die Ergebnisse an. {svgEmoji("🙂")}</p> :
                            (! user.dates.yes.length && ! user.dates.maybe.length && ! user.dates.no.length ) &&
                            <p className="card-text">Was kann ich für dich tun, {user.name}? Möchtest du dich selbst in die Liste eintragen oder die bisherigen Zwischenergebnisse sehen?</p>
                        }
                    </div>
            }{
                progress === 4 &&
                    <div className="mb-3">
                        {hints}
                        {
                            settings.dates.map((date, index) =>
                                <FadeIn from="bottom" positionOffset={30} delayInMilliseconds={index*200} durationInMilliseconds={600}>
                                    <SingleDateRadio date={date.date} key={date._id} handleUserChange={handleUserChange} user={user}/>
                                </FadeIn>
                            )
                        }
                        {/* <p>{JSON.stringify(user)}</p> */}
                    </div>
            }{
                progress === 5 &&
                    <div className="mb-3">
                        {hints}
                        <DateSummary user={user}/>
                        <p className="card-text">Falls du einen Fehler entdeckt hast, klicke einfach auf "Daten ändern" und wir gehen alle Termine noch einmal gemeinsam durch. {svgEmoji("🧐")}</p>
                        <p className="card-text"> Passt so? Dann lass mich deine Eintragungen abschicken. {svgEmoji("🤓")}</p>
                        { (! settings.everybodyCanEdit && method === "POST" ) &&
                            <div>
                                <div className="form-check form-switch text-start mb-3" style={{maxWidth: 500, margin: "auto"}}>
                                    <input className="form-check-input" type="checkbox" name="cookieConsent" id="cookie-consent" onChange={handleCookieConsent} checked={cookieConsent} />
                                    <label className="form-check-label" htmlFor="cookie-consent">Lass mich einen Cookie setzen, damit ich mich an dich erinnern kann. {svgEmoji("🍪")}</label>
                                </div>
                                <div className="input-group" style={{maxWidth: 500, margin: "auto"}}>
                                    <span className="input-group-text" id="email-label" aria-label="E-Mail">{svgEmoji("✉️")}</span>
                                    <input id="email" name="email" className="form-control" type="email" placeholder="Deine Mailadresse (optional)" maxLength="40" aria-labelledby="email-label" aria-describedby="email-help" onChange={handleUserChange} value={user.email} />
                                </div>
                                <div id="email-help" className="form-text" style={{maxWidth: 500, marginLeft: "auto", marginRight: "auto"}}>Die Angabe deiner E-Mail-Adresse ist freiwillig und nur nötig, wenn du auch in anderen Browsern nachträglich noch etwas ändern möchtest.</div>
                            </div>
                        }
                    </div>
            }
            {
                (progress === 2 && ! settings.finalDate) &&
                <button type="button" id="add-date-btn" className="btn btn-secondary m-1" onClick={addDate}>Termin hinzufügen</button>
            }
            {
                progress === 3 && settings.finalDate &&
                <ICalendarLink className="btn btn-primary m-1" event={event}>
                    Termin als ICS-Datei herunterladen
                </ICalendarLink>
            }
            {
                (progress === 3 && settings.finalDate && user._id === settings.adminId) ?
                <button type="submit" id="go-next-btn" className="btn btn-primary m-1">Daten ändern</button> :
                progress === 3 ?
                ! settings.finalDate && <button type="submit" id="go-next-btn" className="btn btn-primary m-1">{(! user.dates.yes.length && ! user.dates.maybe.length && ! user.dates.no.length) ? "Eintragen" : "Daten ändern"}</button> :
                progress === 5 ?
                <button type="submit" id="go-next-btn" className="btn btn-primary m-1">Abschicken!</button> :
                progress === 2 && settings.finalDate ?
                <button type="submit" id="go-next-btn" className="btn btn-primary m-1">{new Date(settings.finalDate).toLocaleString([], {dateStyle: "medium", timeStyle: "short"})} als finalen Termin festlegen</button> :
                <button type="submit" id="go-next-btn" className="btn btn-primary m-1">Weiter geht's</button>
            }
            {
                progress === 3 &&
                <button type="button" name="showResults" className="btn btn-secondary m-1" onClick={handleClick}>Ergebnisse sehen</button>
            }
            {
                progress === 5 &&
                <button type="reset" id="reset-btn" className="btn btn-secondary m-1">Daten ändern</button>
            }
            {
                (progress === 2 && setupDone) &&
                <button type="button" id="delete-poll" className="btn btn-danger mx-auto my-2 d-block" onClick={askDeletePoll}>Umfrage löschen <Trash /></button>
            }
        </form>
    )
}

export default Form