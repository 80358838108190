function Footer() {
    const date = (new Date()).getFullYear()
    return (
        <footer className="p-3 bg-primary text-white">
            <p className="w-100 m-0">© {date} Jasper Witkowski | 
            Emojis: Twitter <a className="text-white" rel="nofollow" href="https://twemoji.twitter.com/">Twemoji</a> / <a className="text-white" rel="nofollow" href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a></p>
        </footer>
    )
}

export default Footer