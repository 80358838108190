import { useEffect, useState, useRef } from "react"
import { Chart as ChartJS } from 'chart.js/auto'
import { Doughnut, Bar } from 'react-chartjs-2'
import { PencilSquare, Trash } from "react-bootstrap-icons"

import { FadeIn } from 'react-slide-fade-in'
import TableCell from "./TableCell"
import Comment from "./Comment"
import AddComment from "./AddComment"
import Modal from "./Modal"

function Results(props) {
    const {settings, user, setUser, results, setResults, setErrorCode, setProgress, hints, setHints, host, url, setUrl, method, setMethod, token, svgEmoji, sendData} = props

    const [comments, setComments] = useState([])

    const [comment, setComment] = useState({
        name: user.name,
        emoji: user.emoji,
        content: "",
        userId: user._id
    })

    const commentRef = useRef(null)

    const [sortedDates, setSortedDates] = useState([])
    const [sortedDateCounts, setSortedDateCounts] = useState({
        yes: [],
        maybe: []
    })
    const [mostPopularDates, setMostPopularDates] = useState([])
    const [mostPopularDateCount, setMostPopularDateCount] = useState({})

    const [showModal, setShowModal] = useState(false)

    function fetchResults() {
        fetch(host + "users", {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
        })
        .then(response => {
        if (!response.ok) {
            setErrorCode(response.status)
            return response.text().then(text => {
                throw new Error(text)
            });
        } else {
            return response.json();
        }
        })
        .then(data => { 
            setResults(data.data)
        })
        .catch(error => {
        console.log(error)
        setProgress(-1)
        })
    }

    function fetchComments() {
        fetch(host + "comments")
        .then(response => {
            if (!response.ok && response.status !== 404) {
                setErrorCode(response.status)
                return response.text().then(text => {
                    throw new Error(text)
                });
            } else {
                return response.json();
            }
        })
        .then(data => { 
            setComments(data.data)
        })
        .catch(error => {
        console.log(error)
        setHints(<p className="card-text">Beim Laden der Kommentare ist ein Fehler aufgetreten.</p>)
        setProgress(-1)
        })
    }

    useEffect(() => {
        fetchResults()
        fetchComments()
        setMethod("POST")
        setUrl(host + "comment")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        let datesObj = Object.fromEntries(settings.dates.map(date => [date.date, {yes: 0, maybe: 0, no: 0}]))
        
        const datesArr = settings.dates.map(date => date.date)
        function countDates(arr, value ) {
            for (const date of arr) {
                if (datesArr.includes(date)) {
                    datesObj[date][value] += 1
                }
            }
        }

        for (const result of results) {
            countDates(result.dates.yes, "yes")
            countDates(result.dates.maybe, "maybe")
            countDates(result.dates.no, "no")
        }
        const sortedDatesArr = Object.entries(datesObj).sort((a, b) => b[1].yes - a[1].yes || b[1].maybe - a[1].maybe || a[1].no - b[1].no)

        setMostPopularDateCount(sortedDatesArr[0][1])

        //console.log(sortedDatesArr)
        setMostPopularDates(sortedDatesArr.map((date, index) => 
            (index === 0 || (index > 0 && JSON.stringify(date[1]) === JSON.stringify(sortedDatesArr[0][1]))) &&
            <time dateTime={date[0]} key={date[0]} className="h2 text-primary d-block">{new Date(date[0]).toLocaleString([], {dateStyle: "short", timeStyle: "short"})}</time>
        ))

        setSortedDates(sortedDatesArr.map(date => new Date(date[0]).toLocaleString([], {dateStyle: "short", timeStyle: "short"})))

        let sortedDatesObj = {
            yes: [],
            maybe: []
        }
        for (const date of sortedDatesArr) {
            sortedDatesObj.yes = [...sortedDatesObj.yes, date[1].yes]
            sortedDatesObj.maybe = [...sortedDatesObj.maybe, date[1].maybe]
        }
        setSortedDateCounts(sortedDatesObj)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results])

    const rest = results.length - mostPopularDateCount.yes - mostPopularDateCount.maybe - mostPopularDateCount.no

    
    function makeDoughnutData() {
        const data = {
            labels: [],
            datasets: [
                {
                  label: 'Bevorzugtes Datum',
                  data: [],
                  backgroundColor: [],
                  hoverOffset: 4,
                  borderWidth: 0
                }
            ]
        }
        if (mostPopularDateCount.yes) {
            data.labels.push("Ja")
            data.datasets[0].data.push(mostPopularDateCount.yes)
            data.datasets[0].backgroundColor.push("#d1e7dd")
        } 
        if (mostPopularDateCount.maybe) {
            data.labels.push("Vielleicht")
            data.datasets[0].data.push(mostPopularDateCount.maybe)
            data.datasets[0].backgroundColor.push("#fff3cd")
        } 
        if (mostPopularDateCount.no) {
            data.labels.push("Nein")
            data.datasets[0].data.push(mostPopularDateCount.no)
            data.datasets[0].backgroundColor.push("#f8d7da")
        } 
        if (rest > 0) {
            data.labels.push("noch nicht abgestimmt")
            data.datasets[0].data.push(rest)
            data.datasets[0].backgroundColor.push("#e2e3e5")
        }

        return data
    }
    
    const doughnutData = makeDoughnutData()

    const barData = {
        labels: sortedDates,
        datasets: [
            {
                label: 'Ja',
                data: sortedDateCounts.yes,
                backgroundColor: '#d1e7dd'
            }
        ]
    }

    function getUsersDates() {
        return results.map(result =>
            <tr key={result._id}>
                <th scope="row" className="text-primary">{result.name}
                { ((settings.everybodyCanEdit || (result._id === user._id && token)) && (! settings.finalDate || user._id === settings.adminId)) && 
                    <div className="btn-group m-2">
                        <button type="button" name="editUser" className="btn btn-sm btn-outline-primary" onClick={event => { handleClick(event, result._id) } } aria-label="Bearbeiten"><PencilSquare style={{pointerEvents: "none"}} /></button>
                        <button type="button" name="askDeleteUser" className="btn btn-sm btn-outline-danger" onClick={event => { handleClick(event, result._id) }} aria-label="Löschen"><Trash style={{pointerEvents: "none"}} /></button>
                    </div>
                }
                </th>
                {
                    settings.dates.map(date => 
                        <TableCell key={"tr-" + date._id} date={date.date} userDates={result.dates}/>
                    )
                }
            </tr>
        )
    }

    function handleClick(event, id) {
        const {name} = event.target
        console.log(event)
        if (name === "editUser") {
            const userToEdit = results.find(user => {
                return user._id === id
            })
            setUrl(host + "user/" + id)
            setUser(userToEdit)
            setMethod("PUT")
            if (user._id === settings.adminId) {
                setHints(<p className="card-text">Na, {user.name}? Ich habe gleich erkannt, dass du der Admin bist. Was möchtest du ändern? Titel, Beschreibung, einzelne Termine? Tob dich ruhig aus! {svgEmoji("😉")}</p>)
                setProgress(2)
            } else {
                setHints(<p className="card-text">Stets zu Diensten, {user.name}! Lass uns die Termine noch einmal durchgehen. {svgEmoji("🙂")}</p>)
                setProgress(4)
            }
        } else if (name === "editComment") {
            const commentToEdit = comments.find(comment => {
                return comment._id === id
            })
            setUrl(host + "comment/" + id)
            setComment(commentToEdit)
            setMethod("PUT")
            commentRef.current.scrollIntoView()
        } else {
            if (name === "askDeleteUser") {
                setUrl(host + "user/" + id)
            } else {
                setUrl(host + "comment/" + id)
            }
            setMethod("DELETE")
            setShowModal(true)
        }
    }

    return (
        <div className="results">
            <div className="card-body">
                {hints}
                {
                    results.length <= 1 ?
                    <p className="card-text">Bislang hat außer dir noch niemand an der Umfrage teilgenommen. Deshalb sehen sie noch wenig fancy aus:</p>
                    : (mostPopularDateCount.yes < 2) ?
                    <p className="card-text">Bislang gibt es unter den Terminvorschlägen noch keinen klaren Favoriten. Aber schau es dir gerne selber an:</p>
                    :
                    <div className="row g-2 align-items-center">
                        <div className="col-12 col-xl-4">
                            <FadeIn from="bottom" positionOffset={30} delayInMilliseconds={200} durationInMilliseconds={600}>
                                <div className="card shadow-sm">
                                    <div className="card-body">
                                        <p className="card-text"><Bar className="d-block mb-1" data={barData} style={{overflow: "visible"}} />{mostPopularDates} An {mostPopularDates[1] ? "diesen Terminen" : "diesem Termin"} haben bislang die meisten Teilnehmer*innen Zeit.</p>
                                    </div>
                                </div>
                            </FadeIn>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <FadeIn from="bottom" positionOffset={30} delayInMilliseconds={400} durationInMilliseconds={600}>
                                <div className="card shadow-sm">
                                    <div className="card-body">
                                        <p className="card-text"><Doughnut className="d-block mb-1" data={doughnutData} style={{overflow: "visible"}} /><span className="h2 text-primary">{Math.round(mostPopularDateCount.yes / results.length * 100)} %</span> der Befragten könnten an {mostPopularDates[1] ? "diesen Terminen" : "diesem Termin"} sicher dabei sein{mostPopularDateCount.maybe > 0 && `, ${Math.round(mostPopularDateCount.maybe / results.length * 100)} % vielleicht`}{rest > 0 && `. ${Math.round(rest / results.length * 100)} % haben noch nicht abgestimmt`}.</p>
                                    </div>
                                </div>
                            </FadeIn>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <FadeIn from="bottom" positionOffset={30} delayInMilliseconds={600} durationInMilliseconds={600}>
                                <div className="card shadow-sm">
                                    <div className="card-body">
                                        <p className="card-text"><span className="h1 d-block mb-1">{results.map(result => svgEmoji(result.emoji))}</span><span className="h2 text-primary">{results.length}</span> Menschen haben bisher an der Umfrage teilgenommen.</p>
                                    </div>
                                </div>
                            </FadeIn>
                        </div>
                    </div>
                }
            </div>
            
            <FadeIn from="bottom" delayInMilliseconds={800} durationInMilliseconds={600}>
                <div className="table-responsive mb-3">
                    <table className="table table-hover">
                        <thead className="align-middle bg-primary text-light">
                            <tr>
                                <th scope="col">Name</th>
                                {
                                    settings.dates.map(date =>
                                        <th scope="col" key={"th-" + date._id}><time dateTime={date.date}>{(new Date(date.date)).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})}</time></th>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody className="align-middle">
                            {getUsersDates()}
                        </tbody>
                    </table>
                </div>
            </FadeIn>
        
            <FadeIn from="bottom" delayInMilliseconds={1000} durationInMilliseconds={600}>
                <h2 className="text-primary">{comments && comments.length} {comments ? (comments.length === 1 ? "Kommentar" : "Kommentare") : "Keine Kommentare"}</h2>
                {comments && comments.map(comment => <Comment key={comment._id} emoji={comment.emoji} name={comment.name} content={comment.content} id={comment._id} userId={comment.userId} updatedAt={comment.updatedAt} user={user} handleClick={handleClick} svgEmoji={svgEmoji} />)}
                { token &&
                    <AddComment comment={comment} setComment={setComment} commentRef={commentRef} svgEmoji={svgEmoji} user={user} sendData={sendData} url={url} host={host} setUrl={setUrl} fetchComments={fetchComments} method={method} setMethod={setMethod} setHints={setHints} setProgress={setProgress} token={token} />
                }
            </FadeIn>

            <Modal showModal={showModal} setShowModal={setShowModal} url={url} host={host} setUrl={setUrl} token={token} setHints={setHints} setProgress={setProgress} method={method} setMethod={setMethod} setErrorCode={setErrorCode} setComment={setComment} fetchComments={fetchComments} fetchResults={fetchResults} user={user} />
            

        </div>
    )
}

export default Results