import { PencilSquare } from "react-bootstrap-icons"
import { Trash } from "react-bootstrap-icons"

function Comment(props) {
    const {emoji, name, content, id, userId, updatedAt, user, handleClick, svgEmoji} = props
    return (
        <div className="card mb-3 shadow-sm m-3">
            <div className="row g-0">
                <div className="col-sm-4 col-md-3 col-xl-2 card-body pb-0">
                    <h5 className="card-title d-inline-block align-middle mb-0 mb-sm-2">{svgEmoji(emoji)} {name}</h5>
                    {userId === user._id &&
                        <div className="btn-group ms-3 ms-sm-0 mb-sm-3 d-inline-block d-sm-block">
                            <button type="button" name="editComment" className="btn btn-sm btn-outline-primary" onClick={event => { handleClick(event, id) } } aria-label="Bearbeiten"><PencilSquare style={{pointerEvents: "none"}} /></button>
                            <button type="button" name="askDeleteComment" className="btn btn-sm btn-outline-danger" onClick={event => { handleClick(event, id) }} aria-label="Löschen"><Trash style={{pointerEvents: "none"}} /></button>
                        </div>
                    }
                </div>
                <div className="col-sm-8 col-md-9 col-xl-10 card-body text-start">
                    <p className="card-text">{content}</p>
                    <p className="card-text"><small className="text-muted"><time dateTime={updatedAt}>{new Date(updatedAt).toLocaleString([], {dateStyle: "medium", timeStyle: "short"})}</time></small></p>
                </div>
            </div>
        </div>
    )
}

export default Comment