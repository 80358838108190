function Spinner(props) {
    const {hints} = props
    return (
        <div style={{minWidth: 200}}>
            {hints}
            <div className="spinner-border text-primary mx-3 mb-3 mt-2" role="status"></div>
        </div>
    )
}

export default Spinner