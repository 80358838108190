import { useState } from "react"
import { CheckLg, XLg } from "react-bootstrap-icons"

function AddComment(props) {
    const {svgEmoji, user, sendData, host, url, setUrl, fetchComments, method, setMethod, comment, setComment, commentRef, token} = props

    const [sent, setSent] = useState("")

    function handleChange(event) {
        const {name, value} = event.target
        setComment(prevState => {
            return {
                ...prevState,
                [name]: value 
            }
        })
    }

    function handleSubmit(event) {
        event.preventDefault()
        setSent("pending")
        sendData(url, method, comment, token)
        .then(data => {
            if (! data.success) {
                throw new Error(data.error)
            }
            console.log(data) // JSON data parsed by `data.json()` call
            setComment(prevState => {
                return {
                    name: {...prevState}.name,
                    emoji: {...prevState}.emoji,
                    userId: {...prevState}.userId,
                    content: ""
                }
            })
            setSent("sent")
            setMethod("POST")
            setUrl(host + "comment")
            fetchComments()
            setTimeout(() => {
                setSent("")
            }, 5000)
        })     
        .catch(error => {
            console.log(error)
            setSent("error")
            setTimeout(() => {
                setSent("")
            }, 10000)
        })        
    }

    return (
        <form id="comments-form" className="p-3" ref={commentRef} onSubmit={handleSubmit}>
            <h3 className="mb-3">Hinterlasse einen Kommentar</h3>
            <div className="mb-3">
                <div className="input-group mb-2">
                    <span className="input-group-text" id="name-label" aria-label="Name">{ svgEmoji(user.emoji) }</span>
                    <input id="name" name="name" className="form-control" type="text" readOnly value={user.name} />
                </div>
                <div className="mb-2 text-start">
                    <label htmlFor="comment-content" className="form-label">Kommentar</label>
                    <textarea id="comment-content" name="content" className="form-control" rows={5} onChange={handleChange} value={comment.content} required></textarea>
                </div>
            </div>
            {
                sent === "pending" ? <button type="submit" id="comment-submit-btn" disabled className="btn btn-primary m-3"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span className="visually-hidden">Lade …</span></button> :
                sent === "sent" ? <button type="submit" id="comment-submit-btn" className="btn btn-success m-3"><span aria-hidden="true"><CheckLg /></span> erfolgreich verschickt</button> :
                sent === "error" ? <button type="submit" id="comment-submit-btn" className="btn btn-danger m-3"><span aria-hidden="true"><XLg /></span> Fehler beim Verschicken</button> :
                <button type="submit" id="comment-submit-btn" className="btn btn-primary m-3">Kommentar abschicken</button>
            }
        </form>
    )
}

export default AddComment