function SingleDateRadio(props) {
    const date = new Date(props.date)
    const parsedDate = date.getTime()
    const formattedDate = date.toLocaleString([], {dateStyle: 'full', timeStyle: 'short'})
    return (
        <fieldset className="btn-group d-flex flex-wrap justify-content-center w-100 mb-3">
            <legend><time dateTime={date}>{formattedDate}</time></legend>

            <input type="radio" className="btn-check" name={props.date} id={"date" + parsedDate + "-yes"} value="yes" onChange={props.handleUserChange} checked={props.user.dates.yes.includes(props.date)} required />
            <label className="btn btn-outline-success" htmlFor={"date" + parsedDate + "-yes"}>Ja</label>

            <input type="radio" className="btn-check" name={props.date} id={"date" + parsedDate + "-no"} value="no" onChange={props.handleUserChange} checked={props.user.dates.no.includes(props.date)} />
            <label className="btn btn-outline-danger" htmlFor={"date" + parsedDate + "-no"}>Nein</label>

            <input type="radio" className="btn-check" name={props.date} id={"date" + parsedDate + "-maybe"} value="maybe" onChange={props.handleUserChange} checked={props.user.dates.maybe.includes(props.date)} />
            <label className="btn btn-outline-warning" htmlFor={"date" + parsedDate + "-maybe"} >Vielleicht</label>
        </fieldset>
    )
}

export default SingleDateRadio