import './App.css';
import "@fontsource/nunito-sans";
import "@fontsource/nunito-sans/400-italic.css";
import "@fontsource/nunito-sans/700.css";
import "@fontsource/nunito-sans/700-italic.css";
import "@fontsource/satisfy";
import Header from './components/Header'
import Main from './components/Main'
import Footer from './components/Footer'


function App() {
  return (
    <div className="App">
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
