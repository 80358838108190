import { useState, useRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Star, Trash } from "react-bootstrap-icons"

// pick a date util library
import de from 'dayjs/locale/de';

function DateChooser(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const divRef = useRef()
    const open = Boolean(anchorEl);



    const {index, settings, handleSettingsChange, handleDateChange, handleClick, id, setupDone, svgEmoji} = props

    const pickerTheme = createTheme({
        palette: {
            primary: {
              light: '#6746c3',
              main: '#5E40C9',
              dark: '#492fa7'
            },
            secondary: {
              light: '#e35183',
              main: '#AD1457',
              dark: '#78002e'
            }
        },
        typography: {
            fontFamily: 'var(--bs-font-sans-serif)',
            button: {
                textTransform: 'none'
            },
            overline: {
                textTransform: 'none',
                fontSize: '0.875rem'
            }
        },
        components: {
            MuiPickersCalendarHeader: {
                styleOverrides: {
                    label: {
                        fontSize: ".92rem"
                    }
                }
            }
        }
    })


    const inputComp = props => {
        return (
            <input
                {...props.inputProps}
                className="form-control bg-white"
                type="text"
                readOnly
                onClick={(event) => setAnchorEl(divRef.current)}
                ref={divRef}
          />
        )
      }
      
    return (
        <div className="mb-3 input-group d-inline-flex w-auto flex-nowrap">
            <span className="input-group-text" id={"date-label-" + index} aria-label="Datum">{svgEmoji('📅')}</span>
            <ThemeProvider theme={pickerTheme}>
                <LocalizationProvider locale={de} dateAdapter={AdapterDayjs}>
                    <DateTimePicker 
                        id={"date-" + index}
                        aria-describedby={"date-label-" + index}
                        value={settings.dates[index].date}
                        disablePast
                        inputFormat="DD.MM.YYYY HH:mm"
                        mask="__.__.____ __:__"
                        onChange={event => handleDateChange(event, index)}
                        renderInput={inputComp}
                        open={open}
                        onClose={() => { setAnchorEl(null) }}
                        cancelText="Abbrechen"
                        toolbarTitle="Datum/Uhrzeit wählen"
                        sx={{
                            fontFamily: "Times"
                        }}
                        PopperProps={{
                            anchorEl: anchorEl,
                          }} 
                    />
                </LocalizationProvider>
            </ThemeProvider>
            { setupDone &&
                <div>
                    <input type="checkbox" className="btn-check" id={"finalDate-" + index} name="finalDate" value={settings.dates[index].date} checked={settings.finalDate === settings.dates[index].date} onChange={handleSettingsChange} />
                    <label className="btn btn-outline-primary ms-2 rounded" htmlFor={"finalDate-" + index}><Star /></label>            
                </div>
            }
            <button type="button" name="removeDates" className={"btn btn-danger ms-2 rounded" + (settings.dates.length === 1 || settings.finalDate === settings.dates[index].date ? " disabled" : "")} aria-label="entfernen" onClick={(event) => { handleClick(event, id) }}><Trash /></button>
            {/* <p>{JSON.stringify(settings.dates[index].date)}</p> */}
        </div>
    )
}

export default DateChooser

